import UAParser from 'ua-parser-js'
import { computed, ref, onMounted, type ComputedRef, type Ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { Label, trackModal } from '@/analytics/mfa'
import notifier from '@/utils/notifier'
import { createEnrollmentTicket, StateManager } from '../utils'

const PROVIDER_PREFIX = /^(auth0|google-oauth2)\|/

enum Platform {
  Android = 'android',
  iOS = 'ios',
  Other = 'other',
}

enum Authenticator {
  GoogleAuthenticator = 'Google Authenticator',
  DuoMobile = 'Duo Mobile',
  OnePassword = '1Password',
  LastPass = 'LastPass',
}

const AUTHENTICATOR_TO_LINKS_MAP: {
  [authenticator in Authenticator]: {
    [device in Platform]: string
  }
} = {
  [Authenticator.GoogleAuthenticator]: {
    [Platform.Android]:
      'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2',
    [Platform.iOS]:
      'https://apps.apple.com/us/app/google-authenticator/id388497605',
    [Platform.Other]:
      'https://support.google.com/accounts/answer/1066447?hl=en',
  },
  [Authenticator.DuoMobile]: {
    [Platform.Android]:
      'https://play.google.com/store/apps/details?id=com.duosecurity.duomobile',
    [Platform.iOS]: 'https://apps.apple.com/us/app/duo-mobile/id422663827',
    [Platform.Other]:
      'https://duo.com/product/multi-factor-authentication-mfa/duo-mobile-app',
  },
  [Authenticator.OnePassword]: {
    [Platform.Android]:
      'https://play.google.com/store/apps/details?id=com.agilebits.onepassword',
    [Platform.iOS]:
      'https://apps.apple.com/us/app/1password-password-manager/id568903335',
    [Platform.Other]: 'https://1password.com/',
  },
  [Authenticator.LastPass]: {
    [Platform.Android]:
      'https://play.google.com/store/apps/details?id=com.lastpass.lpandroid',
    [Platform.iOS]:
      'https://apps.apple.com/us/app/lastpass-password-manager/id324613447',
    [Platform.Other]: 'https://www.lastpass.com/',
  },
}

const usePlatform = () => {
  const platform = ref<Platform>(Platform.Other)

  const getPlatform = (): Platform => {
    const { device, os } = new UAParser().getResult()
    const deviceType = device.type?.toLowerCase()
    const osName = os.name?.toLowerCase()

    if (deviceType == 'mobile' || deviceType == 'tablet') {
      if (osName === 'android') return Platform.Android
      if (osName === 'ios') return Platform.iOS
    }

    return Platform.Other
  }

  onMounted(() => (platform.value = getPlatform()))

  return { platform }
}

const useLinks = (platform: Ref<Platform>) => {
  const getLinks = (authenticator: Authenticator): string => {
    const links = AUTHENTICATOR_TO_LINKS_MAP[authenticator]
    return links ? links[platform.value] : '#'
  }

  return { getLinks }
}

const useSteps = (getLink: (authenticator: Authenticator) => string) => {
  const { t } = useI18n()
  return computed(() =>
    Array.from({ length: 3 }, (_, index) => {
      const step = t(`steps.${index}`)
      return index === 0
        ? Object.values(Authenticator).reduce(
            (acc, authenticator) =>
              acc.replace(
                authenticator,
                `<a href="${getLink(
                  authenticator
                )}" target="_blank">${authenticator}</a>`
              ),
            step
          )
        : step
    })
  )
}

const useEnrollment = (
  accessToken: ComputedRef<string>,
  oidcUser: ComputedRef<{ sub: string }>,
  isRegistration: boolean = false,
  userType?: 'Researcher' | 'Participant'
) => {
  const isLoading = ref(false)
  const stateManager = new StateManager()

  const handlePanelEnable = async () => {
    isLoading.value = true
    try {
      const oidcUserId = oidcUser.value?.sub.replace(PROVIDER_PREFIX, '')
      const ticketUrl = await createEnrollmentTicket(
        oidcUserId,
        accessToken.value
      )

      if (ticketUrl) {
        trackModal(Label.ENABLE_BUTTON, isRegistration, userType)
        stateManager.updateLastEnabled()
        window.location.href = ticketUrl
      } else {
        notifier.error(
          "Sorry, we're experiencing some issues. Please try again. If the problem persists, please contact support."
        )
      }
    } finally {
      isLoading.value = false
    }
  }

  return { isLoading, handlePanelEnable }
}

export default (
  isRegistration = false,
  userType?: 'Researcher' | 'Participant'
) => {
  const { getters } = useStore()
  const accessToken = computed(() => getters['oidc/oidcAccessToken'])
  const oidcUser = computed(() => getters['oidc/oidcUser'])

  const { platform } = usePlatform()
  const { getLinks } = useLinks(platform)
  const steps = useSteps(getLinks)
  const { isLoading, handlePanelEnable } = useEnrollment(
    accessToken,
    oidcUser,
    isRegistration,
    userType
  )

  return {
    isLoading,
    handlePanelEnable,
    steps,
  }
}
