import type { Module } from 'vuex'
import type { QuestionConfig } from '@/views/auth/participant/waitlist-v2/types'

type WaitlistFormData = {
  [K in QuestionConfig['filter_id']]?: string | string[]
}

export type State = {
  waitlistFormData: WaitlistFormData
}

export const SAVE_WAITLIST_FORM_DATA = 'SAVE_WAITLIST_FORM_DATA'

export default {
  namespaced: true,

  state: {
    waitlistFormData: {},
  } as State,

  mutations: {
    [SAVE_WAITLIST_FORM_DATA](
      state: State,
      waitlistFormData: Partial<WaitlistFormData>
    ) {
      state.waitlistFormData = waitlistFormData
    },
  },

  actions: {
    saveWaitlistData(
      { state, commit },
      waitlistData: Partial<WaitlistFormData>
    ) {
      commit(SAVE_WAITLIST_FORM_DATA, {
        ...state.waitlistFormData,
        ...waitlistData,
      })
    },
  },

  getters: {
    waitlistFormData: (state: State) => state.waitlistFormData,
  },
} satisfies Module<State, unknown>
