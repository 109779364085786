import { type FunctionalComponent } from 'vue'
import { featureIsEnabled } from './index'

/**
 * MA (4/8/21): At the moment, this component is only
 * usable with flags that are of Boolean value (true/false).
 *
 * Usage:
 * <p-feature-toggle flag="SOME-FEATURE-FLAG-STRING">
 *   <template #on>
 *     ON
 *   </template>
 *   <template #off>
 *     OFF
 *   </template>
 * </p-feature-toggle>
 *
 * <p-feature-toggle flag="SOME-FEATURE-FLAG-STRING">
 *     ON
 * </p-feature-toggle>
 *
 * <p-feature-toggle flag="featureIsEnabled => featureIsEnabled('SOME-FEATURE-FLAG-STRING') || featureIsEnabled('SOME-FEATURE-OTHER-FLAG-STRING')">
 */

type Flag = Parameters<typeof featureIsEnabled>[0]
type FlagFn = (callback: typeof featureIsEnabled) => boolean

const FeatureToggle: FunctionalComponent<{
  flag: Flag | FlagFn
}> = ({ flag }, { slots: { on, off, default: defaultSlot } }) => {
  const isEnabled =
    typeof flag === 'function' ? flag(featureIsEnabled) : featureIsEnabled(flag)
  return isEnabled ? on?.() || defaultSlot?.() : off?.()
}

export default FeatureToggle
