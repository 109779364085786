import { FLAG_PI_ONBOARDING_UX } from '@/integrations/launchDarkly/active-flags'
import store from '@/store'
import { isParticipantMfaRecoveryEnabled, hasTokenExpired } from '@/utils/auth'

export default [
  {
    path: '',
    component: () => import('@/components/layout/ParticipantOnboarding.vue'),
    children: [
      {
        path: '',
        component: () => import('@/views/auth/common/CenteredLayout.vue'),
        children: [
          {
            path: 'participant/onboarding',
            name: 'auth.participant.onboarding',
            component: () =>
              import('@/views/auth/participant/onboarding/Onboarding.vue'),
            meta: {
              isPublic: true,
              forceAuth: true,
            },
          },
          {
            path: 'participant/onboarding/register',
            name: 'auth.participant.onboarding.register',
            component: () =>
              import(
                '@/views/auth/participant/onboarding/Register/Register.vue'
              ),
            meta: {
              isPublic: true,
              forceAuth: true,
              isOnboardingStep: true,
            },
            props: true,
          },
          {
            path: 'participant/onboarding/id-verification',
            name: 'auth.participant.onboarding.id_verification',
            beforeEnter(to, from, next) {
              const verificationStatus =
                store.getters['auth/user'].onboarding?.id_verification?.status
              if (verificationStatus === 'APPROVED') {
                next({ name: 'auth.participant.onboarding.complete' })
              } else if (verificationStatus === 'DENIED') {
                next({ name: 'auth.participant.onboarding.error' })
              } else {
                next()
              }
            },
            component: () =>
              import(
                '@/views/auth/participant/onboarding/IdVerification/IdVerification.vue'
              ),
            meta: {
              isOnboardingStep: true,
            },
          },
          {
            path: 'participant/onboarding/complete',
            name: 'auth.participant.onboarding.complete',
            component: () =>
              import('@/views/auth/participant/onboarding/Complete.vue'),
          },
          {
            path: 'participant/onboarding/error',
            name: 'auth.participant.onboarding.error',
            component: () =>
              import(
                '@/views/auth/participant/onboarding/VerificationError.vue'
              ),
          },
        ],
      },

      // Onboarding study routes, do not use centered layout
      {
        path: 'participant/onboarding/new',
        name: 'auth.participant.onboarding.new',
        props: true,
        beforeEnter: (to, from, next) => {
          const token = to?.query?.token
          // Check if token has actual content after trimming
          const hasTokenContent =
            typeof token === 'string' && token.trim().length > 0

          const isValidToken = hasTokenContent && !hasTokenExpired(token, 300)
          to.params.isValidToken = isValidToken
          next()
        },
        component: () =>
          import('@/views/auth/participant/new-onboarding/NewOnboarding.vue'),
        meta: {
          forceAuth: true,
          isPublic: true,
          hideNav: true,
          requiresLDFlag: FLAG_PI_ONBOARDING_UX,
        },
      },
      {
        path: 'participant/onboarding/new/register',
        name: 'auth.participant.onboarding.new.register',
        beforeEnter: (to, _, next) => {
          const token = to?.query?.token
          return !store.getters['auth/isLoggedIn'] &&
            (!token || hasTokenExpired(token, 300))
            ? next({ name: 'auth.participant.onboarding.new' })
            : next()
        },
        component: () =>
          import(
            '@/views/auth/participant/new-onboarding/Register/RegisterPage.vue'
          ),
        meta: {
          isPublic: true,
          forceAuth: true,
          isOnboardingStep: true,
        },
        props: true,
      },
      {
        path: 'participant/onboarding/new/security-notice',
        name: 'auth.participant.onboarding.new.security_notice',
        component: () =>
          import(
            '@/views/auth/participant/new-onboarding/Register/SecurityNotice.vue'
          ),
        meta: {
          isPublic: true,
          requiresLDFlag: FLAG_PI_ONBOARDING_UX,
          hideNav: true,
        },
      },
      {
        path: 'participant/onboarding/new/verify-phone',
        name: 'auth.participant.onboarding.new.verify_phone',
        component: () =>
          import(
            '@/views/auth/participant/new-onboarding/Register/VerifyPhone.vue'
          ),
        meta: {
          isPublic: true,
          requiresLDFlag: FLAG_PI_ONBOARDING_UX,
          hideNav: true,
        },
      },
      {
        path: '/participant/onboarding/onboarding-study',
        name: 'auth.participant.onboarding.onboarding_study',
        component: () =>
          import('@/views/auth/participant/onboarding/OnboardingStudy.vue'),
        meta: {
          isOnboardingStep: true,
          hideNav: true,
        },
      },
      {
        path: '/onboarding-study/start',
        name: 'auth.participant.onboarding.onboarding_study.start',
        beforeEnter(to, from, next) {
          if (store.getters['auth/user'].active_study_id !== 'onboarding') {
            next({ name: 'home' })
          } else {
            next()
          }
        },
        component: () =>
          import(
            '@/views/auth/participant/onboarding/InHouseOnboardingStudy.vue'
          ),
        meta: {
          hideNav: true,
        },
      },
      // using onboarding layout and nav to prevent access to anything while a user is sent through onboarding IDV
      {
        path: 'participant/recovery',
        name: 'auth.participant.recovery',
        beforeEnter(to, from, next) {
          if (
            isParticipantMfaRecoveryEnabled() &&
            store.getters['auth/mfa/isInMfaRecovery']
          ) {
            return next()
          } else {
            return next({ name: 'home' })
          }
        },
        component: () =>
          import('@/components/mfa/AccountRecoveryStepsContainer.vue'),
      },
    ],
  },
]
