import axios, { isAxiosError } from 'axios'
import type { Module } from 'vuex'
import { http, endpoints } from '@/api'
import {
  type DataPoint,
  type Batch,
  type BatchInstruction,
  type BatchReportDownloadUrl,
  type Dataset,
  type UnitOfDataSection,
  type UnsavedBatchInstruction,
  type TaskDetails,
  type DatasetUploadUrl,
} from '@/api/types'
import { BATCH_STATUS_READY } from '@/constants'
import { AITaskBuilderDraftStore } from '@/utils/ai-task-builder-draft-store'
import store from '../../../index'
import { handleDraftDataset } from './utils/handle-draft-dataset'

export type Nullable<T> = {
  [P in keyof T]: T[P] | null
}

export type DraftState = Nullable<{
  batchId: string
  batchName: string
  taskDetails: Nullable<{
    task_name: string
    task_introduction: string
    task_steps: string
  }>
  datasetFile: File
  datasetId: string
  datasetPreview: UnitOfDataSection[]
  datasetHasPredeterminedGroupingId: boolean
  instructions: UnsavedBatchInstruction[]
}>

export type State = {
  hydrated: boolean
  batches: Batch[]
  batch: Batch | null
  draft: DraftState
  saving: boolean
  saveError: string | null
}

const CLEAR_DRAFT_STATE = 'CLEAR_DRAFT_STATE'
const SET_BATCH = 'SET_BATCH'
const SET_BATCHES = 'SET_BATCHES'
const SET_DRAFT_STATE = 'SET_DRAFT_STATE'
const SET_HYDRATED_STATE = 'SET_HYDRATED_STATE'
const SET_SAVE_ERROR = 'SET_SAVE_ERROR'
const SET_SAVING = 'SET_SAVING'

const slugify = (str: string): string =>
  str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s.-]/g, '') // Remove non-word chars (except spaces and hyphens or dot)
    .replace(/[\s_-]+/g, '-') // Replace spaces, underscores, and hyphens with a single hyphen
    .replace(/^-+|-+$/g, '') /* Remove leading/trailing hyphens*/

const getDataset = async (datasetId: string) => {
  return await http.get<Dataset>(endpoints.DCTOOL_DATASET(datasetId))
}

const getBatchInstructions = async (batchId: string) => {
  const response = await http.get<{ results: BatchInstruction[] }>(
    endpoints.DCTOOL_BATCH_INSTRUCTIONS(batchId)
  )

  return response.results
}

const getDatasetPreview = async (datasetId: string) => {
  const datapoint = await http.get<DataPoint>(
    endpoints.DCTOOL_DATASET_PREVIEW(datasetId)
  )

  const unitOfDataUrl = datapoint.reference.asset_url
  const { data: datasetPreview } =
    await axios.get<UnitOfDataSection[]>(unitOfDataUrl)
  return datasetPreview
}

export default {
  namespaced: true,

  state: {
    hydrated: false,
    batches: [],
    batch: null,
    draft: {
      batchId: null,
      batchName: null,
      datasetHasPredeterminedGroupingId: null,
      datasetFile: null,
      datasetId: null,
      instructions: [],
      datasetPreview: null,
      taskDetails: null,
    },
    saving: false,
    saveError: null,
  } satisfies State,

  mutations: {
    [CLEAR_DRAFT_STATE](state) {
      state.draft = {
        batchId: null,
        batchName: null,
        datasetHasPredeterminedGroupingId: null,
        datasetFile: null,
        datasetId: null,
        instructions: [],
        datasetPreview: null,
        taskDetails: null,
      }
      state.hydrated = false
    },

    [SET_BATCHES](state, batches: Batch[]) {
      state.batches = batches.sort(
        (a, b) => Date.parse(b.created_at) - Date.parse(a.created_at)
      )
    },

    [SET_BATCH](state, batch: Batch) {
      state.batch = batch
    },

    [SET_DRAFT_STATE](state, draftState: Partial<DraftState>) {
      state.draft = {
        ...state.draft,
        ...draftState,
      }
    },

    [SET_HYDRATED_STATE](state, hydratedState: Partial<DraftState> | null) {
      state.draft = {
        ...state.draft,
        ...hydratedState,
      }
      state.hydrated = true
    },

    [SET_SAVING](state, saving: boolean) {
      state.saving = saving
    },
  },

  actions: {
    async clearDraft({ commit, rootGetters }) {
      const userId = rootGetters['auth/userId']
      const store = new AITaskBuilderDraftStore()
      await store.delete(userId)

      commit(CLEAR_DRAFT_STATE)
    },

    async createBatch(
      { commit, rootGetters },
      {
        name,
        datasetId,
        taskDetails,
      }: {
        name: string
        datasetId: string
        taskDetails: TaskDetails
      }
    ) {
      const batch = await http.post<Batch>(endpoints.DCTOOL_BATCHES, {
        workspace_id: rootGetters['researcher/workspaces/workspaceId'],
        name,
        dataset_id: datasetId,
        task_details: taskDetails,
      })
      commit(SET_BATCH, batch)
      return batch
    },

    async updateBatch(
      { commit },
      { id, changes }: { id: string; changes: Partial<Batch> }
    ) {
      const batch = await http.patch<Batch>(endpoints.DCTOOL_BATCH(id), changes)
      commit(SET_BATCH, batch)
      return batch
    },

    async saveTaskDetails({ state, dispatch }, taskDetails: TaskDetails) {
      await dispatch('persistDraftStateLocally', { taskDetails })

      if (state.draft.batchId) {
        await dispatch('updateBatch', {
          id: state.draft.batchId,
          changes: { taskDetails },
        })
      }
    },

    async createDataset(
      { commit, rootGetters },
      {
        name,
      }: {
        name: string
      }
    ) {
      const dataset = await http.post<Dataset>(endpoints.DCTOOL_DATASETS, {
        workspace_id: rootGetters['researcher/workspaces/workspaceId'],
        name,
      })
      commit(SET_DRAFT_STATE, { datasetId: dataset.id })
      return dataset.id
    },

    async fetchDataset(
      { commit },
      {
        datasetId,
      }: {
        datasetId: string
      }
    ) {
      const dataset = await getDataset(datasetId)
      commit(SET_DRAFT_STATE, {
        datasetId: dataset.id,
        datasetHasPredeterminedGroupingId:
          dataset.has_predetermined_grouping_id,
      })
      return dataset
    },

    async fetchBatches({ commit, rootGetters }) {
      const batches = await http.get<{ results: Batch[] }>(
        endpoints.DCTOOL_BATCHES,
        {
          params: {
            workspace_id: rootGetters['researcher/workspaces/workspaceId'],
          },
        }
      )
      commit(SET_BATCHES, batches.results)
    },

    async hydrateState({ commit, rootGetters, state }) {
      const userId = rootGetters['auth/userId']
      if (state.hydrated || !userId) {
        return
      }
      const store = new AITaskBuilderDraftStore()
      const draftState = await store.get(userId)

      commit(SET_HYDRATED_STATE, draftState)
    },

    async downloadTaskResponses(_, { batchId }: { batchId: string }) {
      const reportDownloadUrl = await http.get<BatchReportDownloadUrl>(
        endpoints.DCTOOL_BATCH_REPORT(batchId)
      )

      window.open(reportDownloadUrl.url, '_blank', 'noopener noreferrer')
    },

    async persistDraftStateLocally(
      { commit, rootGetters },
      draftState: Partial<DraftState>
    ) {
      const userId = rootGetters['auth/userId']
      const store = new AITaskBuilderDraftStore()
      await store.set(userId, draftState)

      commit(SET_DRAFT_STATE, draftState)
    },

    async saveDraft({ state, dispatch, commit }): Promise<void> {
      commit(SET_SAVING, true)

      let batchId = state.draft.batchId

      if (!state.draft.batchId) {
        const result = await handleDraftDataset({
          dispatch,
          existingId: state.draft.datasetId,
          file: state.draft.datasetFile,
        })

        if ('error' in result) {
          commit(SET_SAVE_ERROR, result.error)
          commit(SET_SAVING, false)
          return
        }

        // Wait for dataset to be ready before proceeding
        await result.readyPromise
        await dispatch('fetchDataset', { datasetId: result.datasetId })

        const { id } = await dispatch('createBatch', {
          name: state.draft.batchName,
          datasetId: result.datasetId,
          taskDetails: state.draft.taskDetails,
        })
        batchId = id
      } else {
        await dispatch('fetchDataset', { datasetId: state.draft.datasetId })
      }

      if (state.draft.instructions?.length) {
        await dispatch('upsertInstructions', {
          batchId,
          unsavedInstructions: state.draft.instructions,
        })
      }

      await dispatch('persistDraftStateLocally', { batchId })
      commit(SET_SAVING, false)
    },

    async setBatchAsDraft({ commit, dispatch }, batchId) {
      try {
        const batch = await http.get<Batch>(endpoints.DCTOOL_BATCH(batchId))
        commit(SET_BATCH, batch)

        if (batch.status === 'READY') {
          throw new Error(
            `Batch ${batch.name} has already been setup into tasks and cannot be edited.`
          )
        }

        const [dataset, datasetPreview, instructions] = await Promise.all([
          getDataset(batch.datasets[0]!.id),
          getDatasetPreview(batch.datasets[0]!.id),
          getBatchInstructions(batch.id),
        ])

        const draftState = {
          batchId: batch.id,
          batchName: batch.name,
          datasetHasPredeterminedGroupingId:
            dataset.has_predetermined_grouping_id,
          datasetId: dataset.id,
          datasetPreview,
          instructions,
          taskDetails: batch.task_details,
        } satisfies Partial<DraftState>

        await dispatch('clearDraft')
        await dispatch('persistDraftStateLocally', draftState)
        commit(SET_HYDRATED_STATE, draftState)
      } catch (err) {
        if (isAxiosError(err)) {
          // what should we do here?
        }
      }
    },

    async uploadDataset(
      _,
      {
        datasetId,
        file,
      }: {
        datasetId: string
        file: File
      }
    ) {
      // grab presigned upload url and upload directly to S3
      const filename = slugify(file.name)
      await http
        .get<DatasetUploadUrl>(
          endpoints.DCTOOL_DATASET_UPLOAD_URL(datasetId, filename)
        )
        .then(async response => {
          if (response.http_method === 'PUT') {
            // Using axios directly instead of http because it adds other access tokens we don't need
            await axios.put(response.upload_url, file, {
              headers: {
                'Content-Type': 'application/octet-stream',
              },
            })
          }
        })
    },

    async upsertInstructions(
      _,
      {
        batchId,
        unsavedInstructions,
      }: { batchId: string; unsavedInstructions: UnsavedBatchInstruction[] }
    ) {
      await http.put<{ results: BatchInstruction[] }>(
        endpoints.DCTOOL_BATCH_INSTRUCTIONS(batchId),
        { instructions: unsavedInstructions }
      )
    },

    async setupBatch(
      { getters, commit },
      {
        batch_id,
        dataset_id,
        tasks_per_group,
      }: {
        batch_id?: string
        dataset_id?: string
        tasks_per_group?: number
      }
    ) {
      await http.post(
        endpoints.DCTOOL_BATCH_SETUP(batch_id ?? getters.batch.id),
        {
          dataset_id: dataset_id ?? getters.batch.datasets[0].id,
          tasks_per_group,
        }
      )
      // TODO: probably should be a pusher event post-launch
      commit(SET_BATCH, {
        ...getters.batch,
        status: BATCH_STATUS_READY,
      })
    },
  },

  getters: {
    batches: ({ batches }) => batches,
    batch: ({ batch }) => batch,
    batchId: state => {
      if (!state.draft.batchId && !state.hydrated) {
        store.dispatch('researcher/aiTaskBuilder/hydrateState')
      }
      return state.draft.batchId
    },
    batchName: state => {
      if (!state.draft.batchName && !state.hydrated) {
        store.dispatch('researcher/aiTaskBuilder/hydrateState')
      }
      return state.draft.batchName
    },
    taskDetails: state => {
      if (!state.draft.taskDetails && !state.hydrated) {
        store.dispatch('researcher/aiTaskBuilder/hydrateState')
      }
      return state.draft.taskDetails
    },
    datasetHasPredeterminedGroupingId: state => {
      if (!state.draft.datasetHasPredeterminedGroupingId && !state.hydrated) {
        store.dispatch('researcher/aiTaskBuilder/hydrateState')
      }
      return state.draft.datasetHasPredeterminedGroupingId
    },
    datasetId: ({ draft }) => draft.datasetId,
    datasetFile: state => {
      if (!state.draft.datasetFile && !state.hydrated) {
        store.dispatch('researcher/aiTaskBuilder/hydrateState')
      }
      return state.draft.datasetFile
    },
    datasetPreview: state => {
      if (!state.draft.datasetPreview && !state.hydrated) {
        store.dispatch('researcher/aiTaskBuilder/hydrateState')
      }
      return state.draft.datasetPreview
    },
    instructions: state => state.draft.instructions,
    isSaving: state => state.saving,
  },
} satisfies Module<State, unknown>
