import analytics from '@/analytics'
import { RESEARCHER_APP_INITIAL_LOAD_FINISH } from '@/analytics/events'
import { setDefaultHeaderAuthorization } from '@/api/http'
import { USER_STATUSES } from '@/api/types/user'
import pendo from '@/integrations/pendo'
import rollbar from '@/integrations/rollbar'
import store from '@/store'
import { isMfaEnabled, isParticipantMfaRecoveryEnabled } from '@/utils/auth'
import { checkStudyIsDcToolStudy } from '@/utils/check-study-is-dctool-study'
import {
  setCookieBasedFlags,
  isCookieFeatureToggled,
} from '@/utils/cookieBasedFeatureFlag'
import { setPageTitle } from '@/utils/setPageTitle'
import shouldRedirectActiveStudy from '@/utils/shouldRedirectActiveStudy'
import {
  isStepEnabled,
  isOnboardingCompleted,
} from '@/views/auth/participant/onboarding/utils'
import { routeUnderDevelopment } from './feature-flagged-route-guards'
import handleGlobalNotifications from './global-notifications'

let firstLoad = true

const afterEach = (to, _from) => {
  store.dispatch('global/setLoading', false)
  store.dispatch('global/setLongLoading', false)

  // Update page title based on route meta
  if (to.meta.title) {
    const title =
      typeof to.meta.title === 'function' ? to.meta.title(to) : to.meta.title
    setPageTitle(title)
  } else {
    setPageTitle('')
  }

  if (firstLoad) {
    // This helps us track time to first meaningful paint for the user
    pendo.track(RESEARCHER_APP_INITIAL_LOAD_FINISH)
    firstLoad = false
  }
  setTimeout(() => {
    // send page view data to various integrations
    analytics.page()
  }, 500)
}

const handleAuthenticatedUserSetup = async (oidcUser, { to, next }) => {
  const handleRedirect = (to, next) =>
    to.path === '/' ? next({ name: 'home' }) : next(to)

  const { sub, externalUserId } = oidcUser
  const { getters, dispatch, state } = store

  // Access token is present, but full user data hasn't been fetched yet.
  // We need to retrieve complete user information and MFA enrollments from the API
  // before proceeding. This ensures all necessary user data is available for the session.
  const accessToken = getters['oidc/oidcAccessToken']
  setDefaultHeaderAuthorization(accessToken)

  await dispatch('auth/setupUser', externalUserId, { root: true })

  try {
    const apiUser = store.getters['auth/user']
    await state?.auth?.launchDarklySetUserPromise
    const isFlagEnabled = isMfaEnabled()

    if (apiUser && isFlagEnabled) {
      await dispatch(
        'auth/mfa/fetchMfaEnrollments',
        { accessToken, sub },
        { root: true }
      )
    }
  } catch (error) {
    rollbar.error(error)
  }

  return handleRedirect(to, next)
}

let firstBeforeEachCall = true
const beforeEach = async (to, from, next) => {
  // if researcher is banned then we dont want to run the calls on the routes
  // they seem to be running over and over on 403 reponses.
  if (
    store?.state?.auth?.user?.status === 'BANNED' &&
    store?.state?.auth?.user?.user_type === 'Researcher'
  ) {
    store.dispatch('global/setLongLoading', false)
    store.dispatch('global/setLoading', false)
    return next(false)
  }

  /** ------------------------------------------------------ >
   * TODO: consider moving this logic elsewhere and/or using
   * something other than LD for feature flagging.
   *
   */
  if (firstBeforeEachCall) {
    setCookieBasedFlags(to.query)
    firstBeforeEachCall = false
  }
  // -------------------------------------------------------->

  if (
    to.meta.cookieFeatureFlag &&
    !isCookieFeatureToggled(to.meta.cookieFeatureFlag)
  ) {
    return next({ name: 'home' })
  }

  if (store.state.global.maintenance === true) {
    return next(false)
  }

  // Handle requiresLDFlag route protection logic
  if (await routeUnderDevelopment(to, store)) {
    return next({ name: 'feature-under-development' })
  }

  if (!to.meta.isPublic || to.meta.isCallback) {
    store.dispatch('global/setLoading', true)
  }

  if (to.meta.isPublic && !to.meta.forceAuth) {
    return next()
  }

  const hasAccess = await store.dispatch('oidc/oidcCheckAccess', to)

  if (!hasAccess) {
    // If we don't have access at this point, the client will do a
    // redirect to auth server, so we can return early here.
    return next(false)
  }

  const oidcUser = store.getters['oidc/oidcUser']
  const apiUser = store.getters['auth/user']

  if (oidcUser?.sub && !apiUser.id) {
    return await handleAuthenticatedUserSetup(oidcUser, { to, next })
  }

  // From here on, we have a user in the store

  // If a user is IN_MFA_RECOVERY state, prevent them seeing anything other than this
  if (
    isParticipantMfaRecoveryEnabled() &&
    apiUser.status === USER_STATUSES.IN_MFA_RECOVERY
  ) {
    if (to.name !== 'auth.participant.recovery') {
      return next({ name: 'auth.participant.recovery' })
    }
    return next()
  }

  const activeStudyId = store.getters['auth/activeStudyId']
  let isDCToolStudy = false

  if (activeStudyId && activeStudyId !== 'onboarding') {
    await store
      .dispatch('participant/studies/fetchSingle', activeStudyId)
      .then(res => {
        isDCToolStudy = checkStudyIsDcToolStudy(res)
      })
  }

  // Display messages and notifications etc
  handleGlobalNotifications(to)

  /**
   * Redirect users to the study page if they have an active study that isn't the onboarding study
   * nor a DC Tool study
   */
  if (shouldRedirectActiveStudy(to.name, activeStudyId, isDCToolStudy)) {
    return next({ name: 'study', params: { id: activeStudyId } })
  }

  // Lock participant into onboarding flow if not completed
  // Redirection if onboarding step is not enabled

  if (to.meta.isOnboardingStep) {
    const step = to.name?.split('.').pop()
    if (!isStepEnabled(step)) {
      return next({ name: 'auth.participant.onboarding' })
    }
  }

  const user = store.getters['auth/user']
  const userType = user?.user_type?.toLowerCase()

  if (user && userType === 'researcher') {
    attachRemainingResearcherTitlesToMeta(to)
  }

  if (user && userType === 'participant') {
    const userCompletedOnboarding = isOnboardingCompleted()
    const isOnboardingPage = to.name.includes('onboarding')

    // If onboarding not completed, protect all app routes besides onboarding pages, account page, and submission complete page
    if (
      !isOnboardingPage &&
      ![
        'submissions.complete',
        'account.general',
        'balance_hub',
        'account.linkPaypal',
      ].includes(to.name)
    ) {
      if (
        !user.has_taken_studies &&
        user.status !== USER_STATUSES.OK &&
        user.status !== USER_STATUSES.IN_MFA_RECOVERY
      ) {
        return next({ name: 'auth.participant.onboarding.error' })
      }
      return userCompletedOnboarding
        ? next()
        : next({ name: 'auth.participant.onboarding' })
    }

    if (isOnboardingPage) {
      if (to.name.includes('onboarding.error')) {
        // Protect onboarding error if user is not banned
        if (user.status === USER_STATUSES.OK) {
          return next({ name: 'auth.participant.onboarding' })
          // Allow onboarding error page only if user is banned and onboarding is completed
        } else {
          return userCompletedOnboarding
            ? next()
            : next({ name: 'auth.participant.onboarding' })
        }
      }

      if (
        user.onboarding?.id_verification?.status === 'IN_PROGRESS' &&
        to.name !== 'auth.participant.onboarding.id_verification'
      ) {
        return next({ name: 'auth.participant.onboarding.id_verification' })
      }

      /*
           If user is banned, and has all onboarding steps done, redirect to unable to verify account page.
           We still want to let the user finish the onboarding even if banned halfway through, so we gather data on bad participants.
           We use user.is_verified to differentiate users that were banned during onboarding from the ones that were banned during studies.
        */
      if (
        user.status !== USER_STATUSES.OK &&
        !user.is_verified &&
        userCompletedOnboarding
      ) {
        return next({ name: 'auth.participant.onboarding.error' })
      }

      // Protect onboarding complete route if onboarding not fully completed
      if (to.name.includes('onboarding.complete')) {
        return userCompletedOnboarding
          ? next()
          : next({ name: 'auth.participant.onboarding' })
      }

      // Protect onboarding routes if onboarding is fully completed
      return isOnboardingCompleted() && user.status === USER_STATUSES.OK
        ? next({ name: 'auth.participant.onboarding.complete' })
        : next()
    }
  }

  if (
    store.getters['auth/needsToConfirmUSState'] &&
    !['/researcher/account/general', '/researcher/confirm-US-state'].includes(
      to.path
    )
  ) {
    return next({
      name: 'researcher.confirm_US_state',
      query: { destination: to.path },
      replace: true,
    })
  }

  // Important: keep this here so we ensure we at least call next once
  // if we navigate through all the conditionals above and get here
  next()
}

export { beforeEach, afterEach }

const attachRemainingResearcherTitlesToMeta = to => {
  if (!to.meta.title) {
    const title = to.path.split('/').pop()
    to.meta.title = title
  }
}
