import type { ContentWarning, Study } from '@/api/types'
import type { Currency } from '@/api/types/study/Study'

export type Preferences = {
  userDeviceTypePreferences: ('desktop' | 'tablet' | 'mobile')[]
  userDeviceRequirementPreferences: (
    | 'audio'
    | 'camera'
    | 'download'
    | 'microphone'
  )[]
  userContentTypePreferences: ContentWarning[]
  userStudyTypePreferences: 'in_study_screening'[]
  userStudyMinRewardPreference: number
  participantHomeCurrency: Currency
  activeSort: string
}

// Check if a study is compatible with the user's preferences
export const isStudyCompatible = (study: Study, preferences: Preferences) => {
  const {
    device_compatibility,
    peripheral_requirements,
    content_warnings,
    is_custom_screening,
    study_reward_by_currency,
  } = study

  const {
    userDeviceTypePreferences = [],
    userDeviceRequirementPreferences = [],
    userContentTypePreferences = [],
    userStudyTypePreferences = [],
    userStudyMinRewardPreference,
    participantHomeCurrency,
  } = preferences

  // Make sure the user has SOME device_compatibility from the study in his profile preferences.
  const userDeviceCompatible = device_compatibility?.some(device =>
    userDeviceTypePreferences.includes(device)
  )

  // Make sure the user has EVERY peripheral_requirements from the study in his profile preferences.
  const userPeripheralCompatible = peripheral_requirements?.every(peripheral =>
    userDeviceRequirementPreferences.includes(peripheral)
  )

  // Make sure the user has EVERY content warning from the study enabled in his profile preferences.
  const contentCompatible = content_warnings.every(warning =>
    userContentTypePreferences.includes(warning)
  )

  // Make sure the study reward is higher than the user's minimum_reward filter
  // Get the reward in the user's home currency
  const homeCurrencyReward =
    study_reward_by_currency[participantHomeCurrency]?.amount
  const rewardCompatible = homeCurrencyReward >= userStudyMinRewardPreference

  // Make sure the user has the study type in his profile preferences.
  const studyTypeCompatible =
    !is_custom_screening ||
    userStudyTypePreferences.includes('in_study_screening')

  return (
    userDeviceCompatible &&
    userPeripheralCompatible &&
    contentCompatible &&
    studyTypeCompatible &&
    rewardCompatible
  )
}
