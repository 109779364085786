import type { SectionConfig, ConfirmDetailsSection } from './types'

export const MAX_QUESTIONS_PER_PAGE: number = 4

export const sectionsKeys = [
  'about-yourself',
  'country-of-residence',
  'date-of-birth',
  'country-of-birth',
  'about-yourself-extra',
  'additional-questions',
] as const

export const confirmDetailsSections: ConfirmDetailsSection[] = [
  {
    key: 'basic-info',
    label: 'Basic information',
  },
  {
    key: 'demographics',
    label: 'Demographics',
  },
  {
    key: 'additional-questions',
    label: 'Additional questions',
  },
]

export const aboutYourselfSection: SectionConfig = {
  key: 'about-yourself',
  title: 'Tell us about yourself',
  description:
    'Please enter your legal name exactly as it appears on your ID issued by your current country of residence. Otherwise, you may not be able to join. For a full list of accepted IDs, see this <a href="" target="_blank">article</a>.',
  questions: [
    {
      id: 'first-name',
      filter_id: 'first-name',
      type: 'text',
      question: 'First name',
      required: true,
      confirm_details_section: 'basic-info',
      auto_complete_value: 'given-name',
    },
    {
      id: 'last-name',
      filter_id: 'last-name',
      type: 'text',
      question: 'Last name',
      required: true,
      confirm_details_section: 'basic-info',
      auto_complete_value: 'family-name',
    },
    {
      id: 'email',
      filter_id: 'email',
      type: 'email',
      question: 'Email',
      required: true,
      confirm_details_section: 'basic-info',
      auto_complete_value: 'email',
    },
  ],
}

export const countryOfResidenceSection: SectionConfig = {
  key: 'country-of-residence',
  title: "What's your country of residence?",
  description:
    'Select the country where you currently live. Want to know who can join Prolific? Check out who can participate in studies on Prolific <a href="" target="_blank">here</a>.',
  questions: [
    {
      id: 'country-of-residence',
      filter_id: 'country-of-residence',
      type: 'select',
      question: 'Country of residence',
      required: true,
      auto_complete_value: 'country-name',
      confirm_details_section: 'demographics',
      // TODO: Get countries
      answers: ['USA', 'Canada', 'Mexico'],
    },
  ],
}

export const dateOfBirthSection: SectionConfig = {
  key: 'date-of-birth',
  title: "What's your date of birth?",
  description:
    'Enter your date of birth carefully. This cannot be changed later.',
  questions: [
    {
      id: 'date-of-birth',
      filter_id: 'date-of-birth',
      type: 'dob',
      question: 'Date of birth',
      confirm_details_section: 'demographics',
      // Hidding the label from the UI because it's already in the title of the section
      hideLabel: true,
      required: true,
    },
  ],
}

export const countryOfBirthSection: SectionConfig = {
  key: 'country-of-birth',
  title: "What's your country of birth?",
  description:
    'Select the country where you were born. This can’t be changed later, so please select carefully.',
  questions: [
    {
      id: 'country-of-birth',
      filter_id: 'country-of-birth',
      type: 'select',
      question: 'Country of birth',
      required: true,
      confirm_details_section: 'demographics',
      // TODO: Map to real countries
      answers: ['USA', 'Canada', 'Mexico'],
    },
  ],
}

export const aboutYourselfExtraSection: SectionConfig = {
  key: 'about-yourself-extra',
  title: 'Tell us about yourself',
  questions: [
    {
      id: 'ethnicity',
      filter_id: 'ethnicity',
      type: 'select',
      question: 'What ethnic group do you belong to?',
      required: true,
      confirm_details_section: 'demographics',
      answers: ['White', 'Black', 'Asian', 'Mixed', 'Other'],
    },
    {
      id: 'sex',
      filter_id: 'sex',
      type: 'select',
      question: 'What is your sex, as recorded on legal / official documents?',
      required: true,
      confirm_details_section: 'demographics',
      answers: ['Male', 'Female', 'Prefer not to say'],
    },
  ],
}

// TODO: Additional questions section rendered only if URL campaign with extra questions

const allSections = [
  aboutYourselfSection,
  countryOfResidenceSection,
  dateOfBirthSection,
  countryOfBirthSection,
  aboutYourselfExtraSection,
]

export default allSections
