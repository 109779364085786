export const studyData = [
  {
    id: 'news_consumption',
    formId: 'akQj4FG7',
    title: 'News consumption',
    studyName: 'A study about Current Events awareness',
    q1: 'How often do you follow current events?',
    q2: "What's your favorite way to get the news and why?",
  },
  {
    id: 'brand_loyalty',
    formId: 'vckl5Xov',
    title: 'Brand loyalty',
    studyName: 'A study about Brand Loyalty',
    q1: 'What factors most influence your loyalty to a brand?',
    q2: 'Describe a situation where a brand gained or lost your trust',
  },
  {
    id: 'environment',
    formId: 'bQxHWPiX',
    title: 'Environment',
    studyName: 'A study about the Green Living Choices',
    q1: 'How important is it to you to make environmentally friendly choices in your daily life?',
    q2: "Tell us about a recent small change you made to be more eco-friendly or why you aren't concerned about being green",
  },
  {
    id: 'money',
    formId: 'GswN5iHb',
    title: 'Money',
    studyName: 'A Study on Spending Money',
    q1: `Have you ever won money before?`,
    q2: 'If you won $1 million, what is the first thing you would spend the money on and why?',
  },
  {
    id: 'wellbeing',
    formId: 'MEvX7G2o',
    title: 'Wellbeing',
    studyName:
      'Morning Rituals: Exploring The Beneficial Effects on Daily Wellbeing',
    q1: 'What habits make the most positive impact to your overall wellbeing for the day, when you wake up?',
    q2: 'Describe a place or an object that brings you a sense of calmness',
  },
]

export const filterSets = [
  {
    id: 'handedness',
    info: 'Right Handed',
    screener: 'Handedness',
  },
  {
    id: 'household_size',
    info: '1 to 4 people',
    screener: 'Household Size',
  },
  {
    id: 'children',
    info: 'No children',
    screener: 'Children',
  },
  {
    id: 'age',
    info: '18-35',
    screener: 'Age',
  },
]

export const getStudyData = (id: string) => {
  return studyData.find(study => study.id === id)
}

export const getFilterSet = (id: string) => {
  return filterSets.find(filterSet => filterSet.id === id)
}

export const getTitleFromFormID = (formId: string) => {
  const study = studyData.find(study => study.formId === formId)
  return study ? study.title : ''
}

export const getFormIdFromUrl = (url: string) => {
  const study = studyData.find(study => url.includes(study.formId))
  return study ? study.formId : ''
}
