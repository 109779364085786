import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { FLAG_PI_WAITLIST_UX } from '@/integrations/launchDarkly/active-flags'
import { sectionsKeys } from '@/views/auth/participant/waitlist-v2/sections'

export default [
  {
    path: '/register/participant/join-waitlist/:section?',
    name: 'auth.waitlist.participant',
    beforeEnter: (
      to: RouteLocationNormalized,
      _: RouteLocationNormalized,
      next: NavigationGuardNext
    ) => {
      // TODO: Fetch URL campaign questions here, add them to the additional-questions section
      // Check if page present in query params, and if page does not exist, redirect to the first page

      const defaultSection = sectionsKeys[0]
      const { section } = to.params

      // Redirect to default section if none provided or if not a valid section
      if (
        !section ||
        !sectionsKeys.includes(section as (typeof sectionsKeys)[number])
      ) {
        return next({
          name: 'auth.waitlist.participant',
          params: { section: defaultSection },
          replace: true,
        })
      }

      // TODO: Prevent user from accessing "future" sections if they haven't completed the previous ones
      // For now, just allow them to access any section

      next()
    },
    component: () =>
      import('@/views/auth/participant/waitlist-v2/WaitlistSection.vue'),
    meta: {
      isPublic: true,
      requiresLDFlag: FLAG_PI_WAITLIST_UX,
    },
  },
  {
    path: '/register/participant/join-waitlist/confirm-details',
    name: 'auth.waitlist.participant.confirm-details',
    component: () =>
      import('@/views/auth/participant/waitlist-v2/ConfirmDetails.vue'),
    meta: {
      isPublic: true,
      requiresLDFlag: FLAG_PI_WAITLIST_UX,
    },
  },
]
