import { featureIsEnabled } from '@/integrations/launchDarkly'
import {
  FLAG_DCT_AI_TASK_BUILDER,
  FLAG_DCT_AI_TASK_BUILDER_RESEARCHER_UI,
} from '@/integrations/launchDarkly/active-flags'
import store from '@/store'

export default [
  {
    path: 'researcher/workspaces/:workspaceId/ai-task-builder',
    name: 'workspaces.ai-task-builder',
    component: () =>
      import(
        '@/views/researcher/workspaces/ai-task-builder/AITaskBuilderDashboard.vue'
      ),
    meta: {
      title: 'AI task builder',
    },
    async beforeEnter(to, from, next) {
      // can't use requiresLDFlag helper on workspace-targeted flags yet
      if (
        !featureIsEnabled(FLAG_DCT_AI_TASK_BUILDER) ||
        !featureIsEnabled(FLAG_DCT_AI_TASK_BUILDER_RESEARCHER_UI)
      ) {
        return next({ name: 'error.404' })
      }

      await store.dispatch('researcher/aiTaskBuilder/fetchBatches')

      if (store.state.researcher.aiTaskBuilder.batches.length === 0) {
        return next({
          ...to,
          name: 'workspaces.ai-task-builder.new',
          replace: true,
        })
      }

      next()
    },
  },
  {
    path: 'researcher/workspaces/:workspaceId/ai-task-builder/new',
    name: 'workspaces.ai-task-builder.new',
    component: () =>
      import(
        '@/views/researcher/workspaces/ai-task-builder/AITaskBuilderUpload.vue'
      ),
    async beforeEnter(to, from, next) {
      // can't use requiresLDFlag helper on workspace-targeted flags yet
      if (
        !featureIsEnabled(FLAG_DCT_AI_TASK_BUILDER) ||
        !featureIsEnabled(FLAG_DCT_AI_TASK_BUILDER_RESEARCHER_UI)
      ) {
        return next({ name: 'error.404' })
      }

      if (store.state.researcher.aiTaskBuilder.draft.batchId) {
        await store.dispatch('researcher/aiTaskBuilder/clearDraft')
      }

      next()
    },
    meta: {
      title: 'New - AI task builder',
    },
  },
  {
    path: 'researcher/workspaces/:workspaceId/ai-task-builder/new/instructions',
    name: 'workspaces.ai-task-builder.new.instructions',
    component: () =>
      import(
        '@/views/researcher/workspaces/ai-task-builder/AITaskBuilderTemplate.vue'
      ),
    async beforeEnter(to, from, next) {
      // can't use requiresLDFlag helper on workspace-targeted flags yet
      if (
        !featureIsEnabled(FLAG_DCT_AI_TASK_BUILDER) ||
        !featureIsEnabled(FLAG_DCT_AI_TASK_BUILDER_RESEARCHER_UI)
      ) {
        return next({ name: 'error.404' })
      }

      await store.dispatch('researcher/aiTaskBuilder/hydrateState')

      if (!store.state.researcher.aiTaskBuilder.draft.datasetFile) {
        return next({
          ...to,
          name: 'workspaces.ai-task-builder.new',
          replace: true,
        })
      }

      next()
    },
    meta: {
      title: 'Instructions - AI task builder',
    },
  },
  {
    path: 'researcher/workspaces/:workspaceId/ai-task-builder/:batchId',
    name: 'workspaces.ai-task-builder.edit',
    component: () =>
      import(
        '@/views/researcher/workspaces/ai-task-builder/AITaskBuilderTemplate.vue'
      ),
    props: { allowReupload: false },
    async beforeEnter(to, from, next) {
      // can't use requiresLDFlag helper on workspace-targeted flags yet
      if (
        !featureIsEnabled(FLAG_DCT_AI_TASK_BUILDER) ||
        !featureIsEnabled(FLAG_DCT_AI_TASK_BUILDER_RESEARCHER_UI)
      ) {
        return next({ name: 'error.404' })
      }

      await store.dispatch(
        'researcher/aiTaskBuilder/setBatchAsDraft',
        to.params.batchId
      )
      next()
    },
    meta: {
      title: 'Edit - AI task builder',
    },
  },
]
