export const setPageTitle = (newTitle: string) => {
  let capitalizedTitle = ''
  if (newTitle) {
    const firstLetter = newTitle.charAt(0).toUpperCase()
    const rest = (newTitle.length > 1 ? newTitle.slice(1) : '').replace(
      /(?<!\s)-(?!\s)/g,
      ' '
    )
    capitalizedTitle = firstLetter + rest
  }

  const appTitle = import.meta.env['VUE_APP_TITLE'] || 'Prolific'
  document.title = capitalizedTitle
    ? `${capitalizedTitle} - ${appTitle}`
    : appTitle
}
