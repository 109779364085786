import { localStorageGetItem } from '@/utils/storage'

/**
 * Get study rewards
 * @param {Object} study
 * @returns {Object}
 */
export const getStudyRewards = study => {
  return {
    reward: study?.study_reward.amount,
    currency: study?.study_reward.currency,
    averageRewardPerHour: study?.study_average_reward_per_hour,
    estimatedRewardPerHour: study?.study_estimated_reward_per_hour,
    fixedCustomScreening: study?.fixed_custom_screening,
  }
}

export const sortTrackMapping = {
  'published_at|date|ascending': 'default_oldest_to_newest',
  'reward|number|descending': 'reward_highest_to_lowest',
  'average_reward_per_hour|number|descending':
    'reward_per_hour_highest_to_lowest',
  'average_completion_time|number|ascending': 'time_shortest_to_longest',
}

export const sortOptions = [
  {
    id: 'published_at|date|ascending',
    name: 'default',
  },
  { id: 'reward|number|descending', name: 'reward' },
  {
    id: 'average_reward_per_hour|number|descending',
    name: 'reward_hour',
  },
  {
    id: 'average_completion_time|number|ascending',
    name: 'time',
  },
]

export const getPreferredSort = () => {
  const storageOption = localStorageGetItem('preferredSort')
  const isStorageOptionValid =
    storageOption && sortOptions.some(option => option.id === storageOption)
  return isStorageOptionValid ? storageOption : sortOptions[0].id
}
